<script setup>
const cart = useCartStore()

const formatter = new Intl.NumberFormat('sr-RS', {
  style: 'currency',
  currency: 'RSD',
  minimumFractionDigits: 2
})

const f = new Intl.NumberFormat('sr-RS')
const t = computed(() => Object.keys(cart?.cart?.totals || 0)?.length !== 0)
const tezina = computed(() => {
  const x = ((cart?.cart?.items_weight || 0) / 1000).toFixed(1)
  return (x === '0.0' ? 'do 0.5' : f.format(x)) + ' kg'
})
</script>

<template>
  <ClientOnly>
    <div>
      <div class="cart_row">
        <div>Iznos</div>
        <span
          v-if="t"
          class="tn"
          v-text="formatter.format((+cart.cart?.totals?.total_items + +cart.cart?.totals?.total_items_tax) / 100)"
        />
      </div>
      <div class="cart_row">
        <div>Dostava</div>
        <span
          class="tn"
          v-text="formatter.format(cart.cart?.totals?.total_shipping / 100)"
        />
      </div>
      <div class="cart_pdv tar">
        Težina pošiljke: {{ tezina }}
      </div>
      <div class="cart_row cnt">
        <div>Ukupno</div>
        <div
          class="total_price tn"
          v-text="formatter.format(cart.cart?.totals?.total_price / 100)"
        />
      </div>
      <div class="cart_pdv tar">
        (PDV je uračunat u cenu)
      </div>
    </div>
  </ClientOnly>
</template>

<style scoped>
.tn {
  font-variant-numeric: tabular-nums;
  font-weight: 800;
}

.cart_price {
  text-decoration: line-through;
  color: tomato;
  font-size: 11px;
  line-height: 1;
}

.tar {
  text-align: right;
}

.cnt {
  display: flex;
  align-items: center;

  &.tar {
    justify-content: flex-end;
  }
}

pre {
  font-size: 13px;
  color: magenta;
}

h3 {
  font-weight: 800;
  font-size: 21px;
  padding-bottom: 16px;
}

.cross_sells {
  padding-top: 128px;
}

strong {
  font-weight: 800;
}

.total_price {
  font-size: 21px;
  font-weight: 800;
}

:deep(small) {
  color: rgb(0 0 0 / .5);
  font-size: 12px;
}

.name {
  font-size: 14px;
}

.remove {
  font-weight: 500;
  font-size: 12px;
}

.sticky {
  position: sticky;
  top: 64px;
}

.cart_pdv {
  font-size: 12px;
  padding-bottom: 32px;
  color: rgb(0 0 0 / .5);
}

.cart_row {
  display: flex;
  justify-content: space-between;
  padding-bottom: 4px;

}
</style>
